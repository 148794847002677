<template>
  <div>
    <el-row>
      <el-col :span="24" style="padding: 0 25%; background-color: #101010;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="概述" name="first">
            <div>
              <img :src="one_img" alt="one" class="aio_img_resize">
              <img :src="two_img" alt="two" class="aio_img_resize">
              <img :src="three_img" alt="two" class="aio_img_resize">
              <img :src="four_img" alt="two" class="aio_img_resize">
              <img :src="five_img" alt="two" class="aio_img_resize">
              <img :src="six_img" alt="two" class="aio_img_resize">
              <img :src="seven_img" alt="two" class="aio_img_resize">
              <img :src="eight_img" alt="two" class="aio_img_resize">
              <img :src="night_img" alt="two" class="aio_img_resize">
            </div>
          </el-tab-pane>
          <el-tab-pane label="常见问题" name="second">配置管理</el-tab-pane>
          <el-tab-pane label="规格" name="third">角色管理</el-tab-pane>
          <el-tab-pane label="购买" name="fourth">定时任务补偿</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>

</template>
<script>
export default {
  data() {
    return {
      // 头部下面图片
      one_img: require('../../../public/images/aioproduct/one.png'),
      two_img: require('../../../public/images/aioproduct/two.png'),
      three_img: require('../../../public/images/aioproduct/three.png'),
      four_img: require('../../../public/images/aioproduct/four.png'),
      five_img: require('../../../public/images/aioproduct/five.png'),
      six_img: require('../../../public/images/aioproduct/six.png'),
      seven_img: require('../../../public/images/aioproduct/seven.png'),
      eight_img: require('../../../public/images/aioproduct/eight.png'),
      night_img: require('../../../public/images/aioproduct/night.png')
    }
  }
}
</script>
<style lang="less" scoped>
// 头部

// 头部下面
::v-deep .el-tabs__nav {
  float: right;
}
::v-deep .el-tabs__header {
  background-color: #101010;
}
.aio_img_resize {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>